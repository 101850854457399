import React from "react";
import Layout from "../../components/organisms/layout/layout";
import SEO from "../../shared/seo";
import { useStaticQuery, graphql } from "gatsby";
import BodyFormGracias from "../../components/organisms/body-form-gracias/bodyFormGracias";

const GraciasPage = () => {
    const data = useStaticQuery(graphql`
        query contentfulFormGracias {
            allContentfulContactenos(
                filter: { titulo: { eq: "Información Contáctanos Gracias" } }
            ) {
                nodes {
                    tituloPrincipal
                    descripcion {
                        json
                    }
                    imagenesEmpleo {
                        file {
                            url
                        }
                        title
                        fluid(maxWidth: 960, quality: 75) {
                            src
                            srcSet
                            base64
                            aspectRatio
                            sizes
                        }
                    }
                }
            }
        }
    `);

    return (
        <Layout
            hideHeaderDesktop={true}
            hideHeaderMobile={true}
            isNotRoot={true}
        >
            <SEO title="Desarrollo Empresarial - Gracias" lang="es" />
            <BodyFormGracias {...data.allContentfulContactenos.nodes[0]} />
        </Layout>
    );
};
export default GraciasPage;
