import React from "react";
import "./bodyFormGracias.scss";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";
import Button from "../../atoms/button/button";

interface IBodyForm {
    tituloPrincipal: string;
    descripcion: any;
    imagenesEmpleo: any;
}

const BodyFormGracias = (props: IBodyForm) => {
    const contentfulRenderOptions = {
        renderNode: {
            [INLINES.HYPERLINK]: ({ data }, children) => (
                <a
                    style={{ color: "#EE2B7B", textDecoration: "underline" }}
                    href={data.uri}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                >
                    {children}
                </a>
            ),
        },
        renderText: (text) =>
            text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    };

    return (
        <>
            <div className="o-bodyForm-gracias">
                <Breadcrumb content={`Inicio / Ley de Emprendimiento`} />
                <div className="o-bodyForm-gracias__container">
                    <div className="o-bodyForm-gracias__left">
                        <div className={`o-bodyForm-gracias__header`}>
                            <h1 className="o-bodyForm-gracias__header-title">
                                {props.tituloPrincipal}
                            </h1>
                            <picture>
                                <source
                                    srcSet={props.imagenesEmpleo[2].file.url}
                                    media="(prefers-color-scheme: dark)"
                                />
                                <source
                                    srcSet={props.imagenesEmpleo[3].file.url}
                                    media="(prefers-color-scheme: light)"
                                />
                                <img
                                    src={props.imagenesEmpleo[3].file.url}
                                    alt="Comfama gacias"
                                    className="side-image-gracias__mobile"
                                />
                            </picture>
                            <p className="o-bodyForm-gracias__header-subtitle">
                                {documentToReactComponents(
                                    props.descripcion.json,
                                    contentfulRenderOptions
                                )}
                            </p>
                            <div className="o-bodyForm-gracias-btn">
                                <Button
                                    id={"btn-volver"}
                                    type={"button"}
                                    classname={`secondary`}
                                    text={"Volver" || "Go Back"}
                                    isDisabled={false}
                                    onClickAction={() => {
                                           window.location.href = "/trabajo-con-proposito/emprendimiento/ruta-de-desarrollo-empresarial";
                                     }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-bodyForm-gracias__right">
                        <picture>
                            <source
                                srcSet={props.imagenesEmpleo[0].file.url}
                                media="(prefers-color-scheme: dark)"
                            />
                            <source
                                srcSet={props.imagenesEmpleo[1].file.url}
                                media="(prefers-color-scheme: light)"
                            />
                            <img
                                src={props.imagenesEmpleo[1].file.url}
                                alt="Comfama gacias"
                                className="side-image-gracias"
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BodyFormGracias;
